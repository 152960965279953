/* eslint-disable react/display-name */
import React, { Children } from "react"

const Hero = React.forwardRef(({ img, children, className, logo }, ref) => {
  return (
    <div
      className={`embed-responsive embed-responsive-21by9 embed-responsive-1by1--sm  ${className}`}
      ref={ref}
    >
      <div className="embed-responsive-item">{img}</div>
      <div className="embed-foreground">{Children.toArray(children)}</div>
    </div>
  )
})

Hero.defaultProps = {}

Hero.propTypes = {}

export default Hero
