import React, { createRef, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SEO from "../components/seo"
import Hero from "../components/hero"

import Reveal from "../components/reveal"

const StyledH1 = styled.h1``

const MissionSection = styled.section`
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
  }
`

const Beliefs = styled.div``

const Belief = styled.div`
  position: relative;
  margin-top: 15%;
  margin-bottom: 15%;
  @media (min-width: 768px) {
    margin-top: 10%;
    margin-bottom: 10%;
  }
`

const StyledHero = styled(Hero)``

const Rounded = styled.div`
  width: 150vw;
  height: calc(175px + 5%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 150px);
  z-index: 1;
  background-color: #fff;
`

export default function Page({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { heroImageDesktop, heroImageMobile, moodImage } = data
  const sources = [
    heroImageMobile.childImageSharp.fluid,
    {
      ...heroImageDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const heroRef = createRef(null)
  const sectionRef = useRef(null)
  if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
  }

  useEffect(() => {
    gsap.to(heroRef.current, {
      yPercent: 50,
      ease: "none",
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
    return () => {}
  }, [sectionRef, heroRef])

  return (
    <>
      <SEO
        title="About us"
        description="We are a creative agency with digital DNA. We help companies embrace change to increase sales, enhance customer experience, and grow brand awareness."
        image={heroImageDesktop.childImageSharp.fluid.src}
      />

      <section className="section-hero position-relative" ref={sectionRef}>
        {sources && (
          <StyledHero
            ref={heroRef}
            img={
              <Img
                objectFit="cover"
                objectPosition="50% 50%"
                fluid={sources}
                alt="Chunk Creative Agency - Amsterdam | London"
              />
            }
          >
            <div className="container-fluid d-flex flex-column h-100 justify-content-center black">
              <div className="row">
                <div className="col-12 text-center">
                  <StyledH1 className="display-2 font-weight-bolder mb-1 mb-md-2 mb-lg-4">
                    <span>About us</span>
                  </StyledH1>
                </div>
              </div>
              <div className="d-none d-lg-flex row justify-content-center">
                <div className="col-12 col-md-7 text-center">
                  <p>
                    We are a creative agency with digital DNA. We help companies
                    embrace change to increase sales, enhance customer
                    experience, and grow brand awareness.
                  </p>
                </div>
              </div>
            </div>
          </StyledHero>
        )}
        <Rounded></Rounded>
      </section>

      <MissionSection className="bg-white position-relative">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <Reveal offset="-1%">
                <StyledH1 className="h1 font-weight-bolder mb-2 mb-md-4">
                  Our Mission
                </StyledH1>
                <p className="display-3 font-weight-light">
                  To help companies grow in an era of digital transformation.
                </p>
              </Reveal>
            </div>
          </div>
        </div>
      </MissionSection>

      <Beliefs className="bg-white">
        <div className="container-fluid">
          <Belief className="row">
            <div className="col-12">
              <div className="row row__title">
                <div className="col-12">
                  <Reveal>
                    <h3 className="h1 font-weight-bolder mb-3 mb-md-6">
                      Our Beliefs
                    </h3>
                  </Reveal>
                </div>
              </div>
              <div className="row row__content">
                <div className="col-12 col-md-6 col-lg-5">
                  <Reveal>
                    <h4 className="h6 font-weight-bolder mb-2">Our vision</h4>
                    <p>
                      Our vision is that brand interaction is the key to
                      communication. Successful communication is based on
                      valuable products, helpful innovations and customer
                      feedback. The customers are kings, and their lives and
                      needs are the inspiration for all communication.
                    </p>
                  </Reveal>
                </div>
                <div className="col-12 col-md-6 offset-lg-1 col-lg-5">
                  <Reveal>
                    <h4 className="h6 font-weight-bolder mt-3 mt-lg-0 mb-2">
                      Work Ethics
                    </h4>
                    <p>
                      We believe that success depends on close collaboration
                      with our clients . We work at our offices, our clients’
                      offices or from home, and we actively encourage cycling
                      and public transport. We strongly believe in a more
                      sustainable world that will also be a wonderful place to
                      live for future generations. So we try to fly, drive and
                      print as little as possible.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
          </Belief>
          <Belief className="row">
            <div className="col-12">
              <div className="row row__title">
                <div className="col-12">
                  <Reveal>
                    <h3 className="h1 font-weight-bolder mb-3 mb-md-6">
                      Recognition
                    </h3>
                  </Reveal>
                </div>
              </div>
              <div className="row row__content">
                <div className="col-12 col-md-6 col-lg-5">
                  <Reveal>
                    <h4 className="h6 font-weight-bolder mb-md-2">
                      Emerce 100 Best Digital Companies
                    </h4>
                    <p>Top 3 position 2017, 2018, 2019</p>
                    <h4 className="h6 font-weight-bolder mt-2 mt-md-4 mb-md-2">
                      FONK Agency Top 50
                    </h4>
                    <p>Top 3 position 2017, 2018, 2019, 2020</p>
                  </Reveal>
                </div>
                <div className="col-12 col-md-6 offset-lg-1 col-lg-5">
                  <Reveal>
                    <h4 className="h6 font-weight-bolder mt-3 mt-md-0 mb-md-2">
                      Awards Selection
                    </h4>
                    <p>
                      Effie Awards, SAN Accent, Dutch Interactive Awards
                      Nominee, FWA, Awwwards, D&AD Global Showcase of the Month,
                      Google Global Creative of the Week, Dutch Interactive
                      Awards Nominee
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
          </Belief>
          <Belief className="row">
            <div className="col-12">
              <div className="row row__title">
                <div className="col-12">
                  <Reveal>
                    <h3 className="h1 font-weight-bolder mb-3 mb-md-6">
                      Our Culture
                    </h3>
                  </Reveal>
                </div>
              </div>
              <div className="row row__content">
                <div className="col-12 col-md-6 col-lg-5">
                  <Reveal>
                    <Img
                      className="w-100 image-blob--1"
                      fluid={moodImage.childImageSharp.fluid}
                      alt="Chunk Creative Agency - Amsterdam | London"
                    />
                  </Reveal>
                </div>
                <div className="col-12 ol-md-6 offset-lg-1 col-lg-5">
                  <Reveal>
                    <h4 className="h6 font-weight-bolder mt-3 mt-lg-0 mb-2">
                      A personal and collaborative approach
                    </h4>
                    <p>
                      We work hard and believe that everyone in our agency
                      should be their best self. Personal development through
                      education and knowledge sharing is key to our culture. And
                      yes, we love a good party and take time to relax together
                      with good wine, Gin & Tonic and (healthy) snacks.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
          </Belief>
        </div>
      </Beliefs>
    </>
  )
}

export const pageQuery = graphql`
  query {
    heroImageDesktop: file(
      relativePath: { eq: "home/ChunkHeader_Desktop_AboutUs.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImageMobile: file(
      relativePath: { eq: "home/ChunkHeader_Mobile_AboutUs.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    moodImage: file(relativePath: { eq: "mood/ChunkMood_5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
